<template>
  <b-row>
    <b-col sm="12">
      <div class="card px-lg-4 px-md-3 px-2 py-3">
        <validation-observer ref="addClientForm">
          <b-form @submit.prevent="handleAddAssignment">
            <b-row>
              <b-col sm="12">
                <label for="name">Client :</label>
                <b-form-input
                  v-model="addForm.client_name"
                  type="text"
                  disabled
                />
              </b-col>
              <b-col sm="12">
                <h4 class="bold my-2">Project Details</h4>
              </b-col>
              <b-col sm="12">
                <div class="d-flex mt-2">
                  <h5 class="mr-2">Project Type</h5>
                  <div class="d-flex">
                    <b-form-radio
                      v-model="addForm.candidate_type"
                      name="expert"
                      value="Executive"
                      class="mr-1"
                    >
                      Expert Search
                    </b-form-radio>
                    <b-form-radio
                      v-model="addForm.candidate_type"
                      name="executive"
                      value="Expert"
                    >
                      Executive Search
                    </b-form-radio>
                  </div>
                </div>
              </b-col>
              <b-col cols="12" sm="6">
                <b-form-group>
                  <label for="title">Title:</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Title"
                    rules="required"
                  >
                    <b-form-input
                      id="title"
                      v-model="addForm.title"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Title"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <b-form-group>
                  <label for="name">Expiry Date:</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Expiry Date"
                    rules="required"
                  >
                    <b-form-datepicker
                      id="datepicker-placeholder2"
                      v-model="addForm.tenture"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit',
                      }"
                      placeholder="Choose a Expiry Date"
                      local="en"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group>
                  <label for="description">Description:</label>
                  <b-form-textarea
                    id="description"
                    v-model="addForm.description"
                    type="text"
                    placeholder="Description"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <b-form-group>
                  <label for="required_candidate">Candidate Required?:</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Candidate Required"
                    rules="required"
                  >
                    <b-form-input
                      id="required_candidate"
                      v-model="addForm.required_candidate"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Candidate Required"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <b-form-group>
                  <label for="required_experience">Candidate Experience:</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Candidate Experience"
                    rules="required"
                  >
                    <b-form-input
                      id="required_experience"
                      v-model="addForm.required_experience"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Candidate Experience"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <b-form-group>
                  <label for="name">Industry:</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Industry"
                    rules="required"
                  >
                    <v-select
                      v-model="addForm.industry"
                      label="title"
                      :state="errors.length > 0 ? false : null"
                      :options="industriesOption"
                      :get-option-label="getSelected"
                      :get-option-value="getOptionValue"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <b-form-group>
                  <label for="name">Function:</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Function"
                    rules="required"
                  >
                    <v-select
                      v-model="addForm.function"
                      label="title"
                      :state="errors.length > 0 ? false : null"
                      :options="functionsOption"
                      :get-option-label="getSelected"
                      :get-option-value="getOptionValue"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <b-form-group>
                  <label for="sub_function">Sub Function:</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Sub Function"
                    rules="required"
                  >
                    <v-select
                      v-model="addForm.sub_function"
                      label="sub_function"
                      :state="errors.length > 0 ? false : null"
                      :options="subFunctionsOption"
                      :get-option-label="getSelected"
                      :get-option-value="getOptionValue"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <h4 class="bold my-2">Location Details</h4>
              </b-col>
              <b-col sm="12">
                <b-form-group>
                  <label for="address_1">Address 1:</label>
                  <b-form-input
                    id="address_1"
                    v-model="addForm.address_1"
                    type="text"
                    placeholder="Address 1"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <b-form-group>
                  <label for="address_2">Address 2:</label>
                  <b-form-input
                    id="address_2"
                    v-model="addForm.address_2"
                    type="text"
                    placeholder="Address 2"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <b-form-group>
                  <label for="city">City:</label>
                  <b-form-input
                    id="city"
                    v-model="addForm.city"
                    type="text"
                    placeholder="City"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <b-form-group>
                  <label for="state">State:</label>
                  <b-form-input
                    id="state"
                    v-model="addForm.state"
                    type="text"
                    placeholder="State"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <b-form-group>
                  <label for="zip">ZIP CODE:</label>
                  <b-form-input
                    id="zip"
                    v-model="addForm.zip"
                    type="text"
                    placeholder="ZIP CODE"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <b-form-group>
                  <label for="name">Country:</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Country"
                    rules="required"
                  >
                    <v-select
                      v-model="addForm.country"
                      label="title"
                      :state="errors.length > 0 ? false : null"
                      :options="countryOption"
                      :get-option-label="getSelected"
                      :get-option-value="getOptionValue"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col lg="6" md="12" sm="6">
                <b-form-group label-for="remote">
                  <div class="d-flex mt-2">
                    <h5 class="mr-2">Is Remote working allowed ?</h5>
                    <div class="d-flex">
                      <b-form-radio
                        v-model="addForm.remote"
                        name="remote"
                        value="true"
                        class="mr-2"
                      >
                        Yes
                      </b-form-radio>
                      <b-form-radio
                        v-model="addForm.remote"
                        name="remote"
                        value="false"
                      >
                        No
                      </b-form-radio>
                    </div>
                  </div>
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <h4 class="bold my-2">Payment Details</h4>
              </b-col>
              <b-col cols="12" sm="6">
                <b-form-group>
                  <label for="hourly_price_phone"
                    >Hourly Pricing (Phone):</label
                  >
                  <b-form-input
                    id="hourly_price_phone"
                    v-model="addForm.hourly_price_phone"
                    type="text"
                    placeholder="Hourly Pricing (Phone)"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <b-form-group>
                  <label for="hourly_price_face"
                    >Hourly Pricing (Face to Face):</label
                  >
                  <b-form-input
                    id="hourly_price_face"
                    v-model="addForm.hourly_price_face"
                    type="text"
                    placeholder="Hourly Pricing (Face to Face)"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <h4 class="bold my-2">Questions</h4>
              </b-col>
              <b-col cols="12">
                <b-row
                  v-for="field in fields"
                  :id="field.id"
                  :key="field.id"
                  ref="row"
                >
                  <b-col cols="12" class="my-1">
                    <div class="d-flex justify-content-between">
                      <h5 class="">Question {{ field.id }}</h5>
                      <div>
                        <b-img
                          @click="removeField(field.id)"
                          class="ml-2"
                          src="./../../assets/images/icons/Icon.png"
                        />
                      </div>
                    </div>
                    <b-form-textarea
                      id="textarea-rows"
                      placeholder="Write Here..."
                      rows="3"
                      v-model="field.content"
                      cols="12"
                    />
                  </b-col>
                </b-row>
                <b-col cols="12" class="px-0">
                  <div
                    @click="addField"
                    class="my-2 d-flex align-items-center"
                    style="cursor: pointer"
                  >
                    <b-avatar
                      :src="require('@/assets/images/icons/plus-circle@2x.png')"
                      variant="light-secondary"
                      class="mr-1"
                    />
                    <h5 class="my-0">Add Another Question</h5>
                  </div>
                  <!-- <b-button variant="primary" @click="addField">
                    <feather-icon icon="PlusIcon" class="mr-25" />
                    <span>Add New</span>
                  </b-button> -->
                </b-col>
              </b-col>
              <b-col cols="12" class="mt-2">
                <b-button
                  class="btn btn-success"
                  type="submit"
                  @click.prevent="handleAddAssignment"
                >
                  Save
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import axios from "@/libs/axios"
import { togglePasswordVisibility } from "@core/mixins/ui/forms"
import {
  BButton,
  BImg,
  BCol,
  BForm,
  BFormDatepicker,
  BFormInput,
  BFormRadio,
  BRow,
  BFormGroup,
  BFormTextarea,
  BAvatar,
} from "bootstrap-vue"
import vSelect from "vue-select"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { required } from "@validations"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import errorResponse from "@/libs/axiosError"

export default {
  components: {
    BFormTextarea,
    ValidationObserver,
    ValidationProvider,
    BRow,
    BImg,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BFormRadio,
    BFormDatepicker,
    BButton,
    vSelect,
    BAvatar,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      addForm: {
        title: "",
        status: "New",
        description: "",
        client_name: "",
        tenture: "",
        country: {},
        required_experience: "",
        remote: "false",
        candidate_type: "Executive",
        client_id: "",
        industry: "",
        function: "",
        sub_function: "",
        required_candidate: "",
        question: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        zip: "",
        hourly_price_phone: "",
        hourly_price_face: "",
      },
      fields: [],
      fieldCount: 1,
      candidateTypeOption: ["Executive", "Expert"],
      countryOption: [],
      functionsOption: [],
      subFunctionsOption: [],
      industriesOption: [],
      clientsOption: [],
      assignmentData: {},
    }
  },
  // watch: {
  //   // whenever question changes, this function will run
  //   addForm(newQuestion, oldQuestion) {
  //     console.log(newQuestion, oldQuestion, "hello")
  //   },
  // },
  mounted() {
    this.getClients()
    this.getAssignmentData()
  },
  methods: {
    async getAssignmentData() {
      this.getCountries()
      this.getIndustries()
      this.getFunctions()
      this.getSubFunctions()

      axios
        .get(`/assignment/${this.$route.params.id}`)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Assignment List`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have successfully fetched Assignment List!`,
            },
          })

          const strArray = res.data.question.split(",")
          console.log("hello")
          strArray.forEach((element) => {
            this.fields.push({ id: this.fieldCount, content: element })
            // eslint-disable-next-line no-plusplus
            this.fieldCount++
          })

          const dataCountry = this.countryOption.find(
            (person) => person.name === res.data.country
          )
          const dataIndustry = this.industriesOption.find(
            (person) => person.name === res.data.industry
          )
          const dataFunction = this.functionsOption.find(
            (person) => person.name === res.data.function
          )
          const dataSubFunction = this.subFunctionsOption.find(
            (person) => person.name === res.data.sub_function
          )
          this.addForm = {
            title: res.data.title,
            client_name: res.data.client_name,
            description: res.data.description,
            tenture: res.data.tenture,
            country: dataCountry,
            required_experience: res.data.required_experience,
            remote: res.data.remote,
            candidate_type: res.data.candidate_type,
            industry: dataIndustry,
            function: dataFunction,
            sub_function: dataSubFunction,
            required_candidate: res.data.required_candidate,
            address_1: res.data.address_1,
            address_2: res.data.address_2,
            city: res.data.city,
            state: res.data.state,
            zip: res.data.zip,
            hourly_price_phone: res.data.hourly_price_phone,
            hourly_price_face: res.data.hourly_price_face,
            id: res.data.id,
          }
        })
        .catch((error) => {
          errorResponse(error, this.$router)
        })
    },
    getClientData() {
      axios
        .get(`/get-client-by-id/${this.addForm.client_id.id}`)
        .then((res) => {
          this.addForm.hourly_price_phone = res.data.hourly_price_phone
          this.addForm.hourly_price_face = res.data.hourly_price_face
        })
        .catch((error) => {
          errorResponse(error, this.$router)
        })
    },
    addField() {
      // eslint-disable-next-line no-plusplus
      this.fieldCount++
      this.fields.push({ id: this.fieldCount, content: "" })
    },
    removeField(id) {
      this.fields = this.fields.filter((obj) => obj.id !== id)
    },
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
    },
    getSelected(option) {
      return (option && option.name) || ""
    },
    getOptionValue(option) {
      return (option && option.name) || ""
    },
    async handleAddAssignment() {
      this.$refs.addClientForm.validate().then((success) => {
        if (success) {
          const question = []
          this.fields.forEach((element) => {
            question.push(element.content)
          })
          console.log(this.addForm, "hello")

          // title: res.data.title,
          //   client_name: res.data.client_name,
          //   description: res.data.description,
          //   tenture: res.data.tenture,
          //   country: dataCountry,
          //   required_experience: res.data.required_experience,
          //   remote: res.data.remote,
          //   candidate_type: res.data.candidate_type,
          //   industry: dataIndustry,
          //   function: dataFunction,
          //   sub_function: dataSubFunction,
          //   required_candidate: res.data.required_candidate,
          //   address_1: res.data.address_1,
          //   address_2: res.data.address_2,
          //   city: res.data.city,
          //   state: res.data.state,
          //   zip: res.data.zip,
          //   hourly_price_phone: res.data.hourly_price_phone,
          //   hourly_price_face: res.data.hourly_price_face,
          //   id: res.data.id,
          const formData = {
            id: this.addForm.id,
            title: this.addForm.title,
            description: this.addForm.description,
            tenture: this.addForm.tenture,
            country: this.addForm.country.name,
            candidate_type: this.addForm.candidate_type,
            industry: this.addForm.industry.name,
            function: this.addForm.function.name,
            sub_function: this.addForm.sub_function.name,
            remote: this.addForm.remote,
            required_candidate: this.addForm.required_candidate,
            required_experience: this.addForm.required_experience,
            question: question.toString(),
            address_1: this.addForm.address_1,
            address_2: this.addForm.address_2,
            city: this.addForm.city,
            state: this.addForm.state,
            zip: this.addForm.zip,
            hourly_price_phone: this.addForm.hourly_price_phone,
            hourly_price_face: this.addForm.hourly_price_face,
          }
          axios
            .post("/edit_assignment", formData)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Assignment Addition`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `You have successfully Created an Assignment!`,
                },
              })

              this.$router.push({
                path: `/details-admin-engagement/${this.$route.params.id}`,
              })
            })
            .catch((error) => {
              errorResponse(error, this.$router)
            })
        }
      })
    },
    async getCountries() {
      axios
        .get("/countries")
        .then((res) => {
          this.countryOption = res.data.countries
        })
        .catch((error) => {
          errorResponse(error, this.$router)
        })
    },
    async getClients() {
      axios
        .get(`/get-clients`)
        .then((res) => {
          this.clientsOption = res.data.clients
        })
        .catch((error) => {
          errorResponse(error, this.$router)
        })
    },
    async getIndustries() {
      axios
        .get(`/industries`)
        .then((res) => {
          this.industriesOption = res.data.industries
        })
        .catch((error) => {
          errorResponse(error, this.$router)
        })
    },
    async getFunctions() {
      axios
        .get(`/functions`)
        .then((res) => {
          this.functionsOption = res.data.candidateFunctions
        })
        .catch((error) => {
          errorResponse(error, this.$router)
        })
    },
    async getSubFunctions() {
      axios
        .get(`/sub_functions`)
        .then((res) => {
          this.subFunctionsOption = res.data.sub_functions
        })
        .catch((error) => {
          errorResponse(error, this.$router)
        })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.button {
  background-color: rgb(3, 77, 133);
  color: #fff;
}

.demo-inline-spacing {
  margin-top: -15px;
}
#present_job {
  overflow: overlay;
}
.title {
  margin-left: 5px;
}
.img {
  margin-right: 10px;
  width: 35px;
  height: 35px;
}

.file_bg {
  background-color: #dcdcdc;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
